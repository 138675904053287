.App {
  text-align: center;
}

.App-logo {
  position: relative;
  height: 40vmin;
  pointer-events: none;
  z-index: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(180deg, #6734DD 0%, #30CDD7 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: cubano;
  src: url(Cubano-Font-Family/Cubano/Cubano.ttf);
}

.cubanoText {
  font-family: cubano;
}

.imgbox {
  display: grid;
  height: 100%;
}
.center-fit {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
  height: 40vmin;
}

/* For Debugging */
.debug {
  border: 5px solid red;
}

.debug2 {
  border: 5px solid yellow;
}

.debug3 {
  border: 5px solid green;
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: absolute;
  height: 40vmin;
  z-index: 3;
}